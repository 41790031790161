import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Art restoration support system`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "8px 0"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGS3yBpHJr/xAAbEAABBQEBAAAAAAAAAAAAAAACAAEDEiIEEf/aAAgBAQABBQJ/bSkzINiQ66lFUI//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BjH//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGBABAQEBAQAAAAAAAAAAAAAAABEBAiH/2gAIAQEABj8CS+riuWZX/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAIUFRMf/aAAgBAQABPyEARoOWYg2chDDhCjvkWwFlzYWz/9oADAMBAAIAAwAAABADL//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAIux//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QUcn/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMXFB/9oACAEBAAE/EEhqUgpuGu/UDWWzejHyieErQBrNa4VMe0Lw9e/J/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hololens",
          "title": "Hololens",
          "src": "/static/4358595e624d45850029c0a8fc5d2aa6/e5166/4_hololens-1.jpg",
          "srcSet": ["/static/4358595e624d45850029c0a8fc5d2aa6/f93b5/4_hololens-1.jpg 300w", "/static/4358595e624d45850029c0a8fc5d2aa6/b4294/4_hololens-1.jpg 600w", "/static/4358595e624d45850029c0a8fc5d2aa6/e5166/4_hololens-1.jpg 1200w", "/static/4358595e624d45850029c0a8fc5d2aa6/eea4a/4_hololens-1.jpg 1280w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><undefined parentName="p">{`
		`}<video {...{
          "src": "/b2915378e00d155cebbbaac211720936/4_xray.mp4",
          "width": "100%",
          "height": "auto",
          "preload": "auto",
          "muted": "true",
          "title": "/b2915378e00d155cebbbaac211720936/4_xray.mp4",
          "autoPlay": true,
          "playsInline": true,
          "loop": true
        }}></video>{`
	`}</undefined></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      